export function getAuthLoginRedirect() {
  return (
    `https://${process.env.REACT_APP_SSO_DOMAIN}/authorize?` +
    `response_type=code` +
    `&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}` +
    `&redirect_uri=${window.location.origin}/login` +
    `&scope=openid profile`
  );
}

export function getAuthLogoutRedirect() {
  return (
    `https://${process.env.REACT_APP_SSO_DOMAIN}/v2/logout?` +
    `client_id=${process.env.REACT_APP_SSO_CLIENT_ID}` +
    `&returnTo=${window.location.origin}/logout?success=true`
  );
}
