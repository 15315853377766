import { Paper } from '@material-ui/core';

export default function Panel({ children }) {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <Paper
        className="px-16 py-12 flex flex-col items-center justify-center"
        variant="outlined">
        {children}
      </Paper>
    </div>
  );
}
