import { createTheme } from '@material-ui/core/styles';

export default function getMUITheme(paletteType = 'light') {
  return createTheme({
    breakpoints: {
      values: {
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
    overrides: {
      MuiAvatar: {
        colorDefault: {
          backgroundColor: '#9e9e9e',
        },
      },
      MuiListItem: {
        button: {
          transition: 'none',
        },
      },
    },
    palette: {
      type: paletteType,
      primary: {
        main: paletteType === 'dark' ? '#8cc1c1' : '#669999',
        dark: '#669999',
      },
      secondary: {
        main: paletteType === 'dark' ? '#ffffff' : '#000000',
      },
      error: {
        main: '#fc6666',
      },
      warning: {
        main: '#f59000',
      },
      info: {
        main: '#3281e8',
      },
      success: {
        main: '#74cccc',
      },
      dataTableBg: paletteType === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'white',
    },
    // props: {
    //   MuiAvatar: {
    //     className: '',
    //   },
    // },
    spacing: 8,
    typography: {
      // Use system font
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });
}
