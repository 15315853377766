import { create } from 'jss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import App from './components/App';
import getApolloClient from './lib/getApolloClient';
import { AuthProvider } from './lib/useAuth';
import { DarkModeProvider } from './lib/useDarkMode';
import './style.css';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const root = createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={getApolloClient()}>
    <AuthProvider>
      <StylesProvider jss={jss}>
        <DarkModeProvider>
          <Router>
            <App />
          </Router>
        </DarkModeProvider>
      </StylesProvider>
    </AuthProvider>
  </ApolloProvider>,
);
