import { createContext, useContext } from 'react';
import useLocalStorage from './useLocalStorage';

const authContext = createContext({});

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    'is-authenticated',
  );
  const value = {
    isAuthenticated,
    setIsAuthenticated,
  };
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}

export default function useAuth() {
  return useContext(authContext);
}
