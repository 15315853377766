import _groupBy from 'lodash/groupBy';
import { Link as RouterLink } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Link, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';

const activityTypeToDescription = {
  ADD_BID: 'Added a bid',
  ADD_COMMENT: 'Added a comment',
  ADD_ISSUE: 'Issue created',
  ATTACH_NOTE: 'Note',
  CREATE_API_TOKEN: 'Created API token',
  DELETE_API_TOKEN: 'Deleted API token',
  DELETE_COMMENT: 'Deleted a comment',
  FLAG_COMMENT: 'Flagged a comment',
  SET_BID_STATUS: 'Bid status changed',
  SET_COMMENT_STATUS: 'Comment status changed',
  SET_DISPLAY_NAME: 'Set display name',
  SET_ISSUE_STATUS: 'Issue status changed',
  SET_SALE_STATUS: 'Sale status changed',
  SET_SALE_VISIBILITY: 'Sale visibility changed',
  SET_USER_STATUS: 'User status changed',
  SET_WEBHOOK_URL: 'Set webhook URL',
};

export default function Activity({ activity }) {
  const grouped = _groupBy(activity, item =>
    new Date(item.createdDate).toLocaleString(),
  );
  return (
    <Timeline>
      {Object.keys(grouped)
        .sort((a, b) => new Date(b) - new Date(a))
        .map(timestamp => (
          <TimelineItem key={timestamp}>
            <TimelineOppositeContent className="w-32 flex-none">
              <Typography variant="caption">{timestamp}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="pt-1">
              {grouped[timestamp].reverse().map((item, index) => (
                <Typography className="mb-4" component="div" key={index}>
                  {activityTypeToDescription[item.type] || item.title}
                  {item.adminUser && (
                    <>
                      {' '}
                      by{' '}
                      <Link
                        component={RouterLink}
                        to={`/users/${item.adminUser.id}`}>
                        {item.adminUser.username}
                      </Link>
                      :
                    </>
                  )}
                  {Object.entries({
                    ...item.details,
                    ...(item.type === 'ATTACH_NOTE'
                      ? { '': item.description }
                      : null),
                    ...(item.type === 'SET_ISSUE_STATUS'
                      ? {
                          'New status': item.description,
                        }
                      : null),
                  }).map(([key, value], index) => (
                    <Typography
                      className="italic"
                      color="textSecondary"
                      component="div"
                      key={index}
                      variant="body2">
                      {key ? `${key}: ` : ''}
                      {value}
                    </Typography>
                  ))}
                </Typography>
              ))}
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

Activity.fragments = {
  activity: gql`
    fragment Activity on Activity {
      createdDate
      description
      type
      adminUser {
        id
        username
      }
      bid {
        id
        index
      }
      comment {
        idDatabase
        body
      }
      issue {
        id
        description
        type
      }
      sale {
        id
        product {
          name
        }
      }
      user {
        id
        username
      }
    }
  `,
};
