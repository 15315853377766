import cx from 'classnames';
import { useMemo, useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CSSBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import getMUITheme from '../lib/getMUITheme';
import Collections from '../pages/Collections';
import Comments from '../pages/Comments';
import Dashboard from '../pages/Dashboard';
import Issues from '../pages/Issues';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Sale from '../pages/Sale';
import SaleTable from '../pages/SaleTable';
import User from '../pages/User';
import UserTable from '../pages/UserTable';
import Nav from './Nav';
import Header from './Header';
import useAuth from '../lib/useAuth';
import useDarkMode from '../lib/useDarkMode';
import { useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';

function App() {
  const [navOpen, setNavOpen] = useState(false);
  const [darkMode] = useDarkMode();
  const theme = useMemo(() => getMUITheme(darkMode ? 'dark' : 'light'), [
    darkMode,
  ]);
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isLarge) {
      setNavOpen(true);
    }
  }, [isLarge]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet titleTemplate="%s - Aftermarket Console"></Helmet>
      <CSSBaseline />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route
          render={() => {
            return isAuthenticated ? (
              <>
                <Nav open={navOpen} onClose={() => setNavOpen(false)} />
                <div className={cx({ 'lg:ml-56': navOpen })}>
                  <Header onToggleNav={() => setNavOpen(!navOpen)} />
                  <Switch>
                    <Route exact path="/">
                      <Dashboard />
                    </Route>
                    <Route path="/collections/:id?">
                      <Collections />
                    </Route>
                    <Route path="/comments/:id?">
                      <Comments />
                    </Route>
                    <Route path="/issues/:id?">
                      <Issues />
                    </Route>
                    <Route path="/sales/:id">
                      <Sale />
                    </Route>
                    <Route path="/sales">
                      <SaleTable />
                    </Route>
                    <Route path="/users/:id">
                      <User />
                    </Route>
                    <Route path="/users">
                      <UserTable />
                    </Route>
                  </Switch>
                </div>
              </>
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
