import { useCallback, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  Drawer,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { formatCurrency } from '../lib/formatters';
import useWaitCursor from '../lib/useWaitCursor';

// a little function to help us with reordering the result
// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/about/examples.md
function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function getInitialFormState() {
  return {
    domains: '',
    heading: '',
    saleType: 'auction',
    slug: '',
    sortType: 'auto',
    subheading: '',
    theme: 'primary',
    type: 'manual',
    visibility: 'public',
  };
}

const COLLECTION_PAGE = gql`
  query CollectionTablePage($showStats: Boolean!) {
    collections {
      createdDate
      domainsCSV
      heading
      id
      saleType
      slug
      sortType
      subheading
      theme
      type
      visibility
      sales @include(if: $showStats) {
        stats {
          saleCountActive
          saleCountActiveBidded
          saleRevenueActive
          saleRevenueSold
          saleViewCount
        }
      }
    }
  }
`;

const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: String!) {
    deleteCollection(input: { id: $id }) {
      success
    }
  }
`;

const SORT_COLLECTIONS = gql`
  mutation SortCollections($sort: [String]!) {
    sortCollections(input: { sort: $sort }) {
      success
    }
  }
`;

const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
      }
      message
      success
    }
  }
`;

export default function Collections() {
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [sortable, setSortable] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [formData, setFormData] = useState(getInitialFormState());
  const cachedFormData = useRef();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const pageQuery = useQuery(COLLECTION_PAGE, {
    notifyOnNetworkStatusChange: true,
    variables: {
      showStats,
    },
  });
  const [deleteCollection] = useMutation(DELETE_COLLECTION);
  const [sortCollections, sortCollectionsQuery] = useMutation(SORT_COLLECTIONS);
  const [updateCollection, updateCollectionQuery] = useMutation(
    UPDATE_COLLECTION,
  );
  const cursor = pageQuery.data?.collections.find(c => c.id === params.id);

  useWaitCursor(
    pageQuery.loading ||
      updateCollectionQuery.loading ||
      sortCollectionsQuery.loading,
  );

  const refreshFormData = useCallback(
    () =>
      setFormData(
        cursor
          ? {
              domains: cursor.domainsCSV?.split(',').join('\n') || '',
              heading: cursor.heading || '',
              saleType: cursor.saleType || '',
              slug: cursor.slug || '',
              sortType: cursor.sortType || '',
              subheading: cursor.subheading || '',
              theme: cursor.theme || '',
              type: cursor.type || '',
              visibility: cursor.visibility || '',
            }
          : cachedFormData.current
          ? { ...cachedFormData.current }
          : getInitialFormState(),
      ),
    [cursor],
  );

  useEffect(() => {
    refreshFormData();
  }, [cursor, refreshFormData]);

  useEffect(() => {
    if (!cursor) cachedFormData.current = { ...formData };
  }, [cursor, formData]);

  useEffect(() => {
    setItems(
      pageQuery.data?.collections || pageQuery.previousData?.collections || [],
    );
  }, [pageQuery.data, pageQuery.previousData]);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) return;
      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
      );
      setItems(newItems);
    },
    [items],
  );

  const onUpdateCollection = useCallback(
    async (id, formData) => {
      try {
        const { data } = await updateCollection({
          variables: { input: { id, ...formData } },
        });
        if (!data.updateCollection.success) {
          window.alert(data.updateCollection.message);
        } else {
          window.alert(`"${formData.heading}" collection successfully saved.`);
          history.push('/collections');
          await pageQuery.refetch();
          cachedFormData.current = null;
          refreshFormData();
        }
      } catch (error) {
        window.alert(`Failed to ${id ? 'update' : 'create'} collection`);
        console.error(error);
      }
    },
    [pageQuery, history, refreshFormData, updateCollection],
  );

  const onDeleteCollection = useCallback(
    async collectionId => {
      try {
        await deleteCollection({
          variables: { id: collectionId },
        });
        history.push('/collections');
        await pageQuery.refetch();
      } catch (error) {
        window.alert('Failed to delete collection');
        console.error(error);
      }
    },
    [deleteCollection, pageQuery, history],
  );

  const onSaveSort = useCallback(async () => {
    try {
      await sortCollections({
        variables: { sort: items.map(item => item.id) },
      });
      await pageQuery.refetch();
      setSortable(false);
    } catch (error) {
      console.error(error);
    }
  }, [items, pageQuery, sortCollections]);

  if (pageQuery.error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {pageQuery.error.message}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>Collections</title>
      </Helmet>
      <div className="px-4 py-3">
        {sortable ? (
          [
            <Button key="save" onClick={onSaveSort}>
              Save
            </Button>,
            <Button
              className="ml-2"
              key="cancel"
              onClick={() => {
                setItems(pageQuery.data.collections);
                setSortable(false);
              }}>
              Cancel
            </Button>,
          ]
        ) : (
          <>
            <Button
              component={RouterLink}
              to="/collections/new"
              endIcon={<AddIcon />}>
              Create
            </Button>
            <Button
              className="ml-2"
              onClick={() => setSortable(true)}
              endIcon={<ImportExportIcon />}>
              Sort
            </Button>
            <Button
              className="ml-2"
              onClick={() => setShowStats(true)}
              endIcon={<EqualizerIcon />}>
              Show Stats
            </Button>
          </>
        )}
      </div>
      {sortable && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="collections">
            {provided => (
              <List
                {...provided.droppableProps}
                disablePadding
                ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={!sortable}
                    key={item.id}>
                    {provided => (
                      <ListItem
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        button
                        className="rounded"
                        component={RouterLink}
                        to={`/collections/${item.id}`}
                        ref={provided.innerRef}
                        selected={
                          !sortable &&
                          location.pathname === `/collections/${item.id}`
                        }
                        style={provided.draggableProps.style}>
                        <ListItemIcon>
                          <DragHandleIcon className="ml-2" />
                        </ListItemIcon>
                        <ListItemText primary={item.heading} />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <TableContainer className={sortable ? 'hidden' : ''}>
        <Table aria-label="collections">
          <TableHead>
            <TableRow>
              <TableCell className="whitespace-nowrap pl-6">URI</TableCell>
              <TableCell>Type</TableCell>
              <TableCell className="whitespace-nowrap">Active Sales</TableCell>
              <TableCell className="whitespace-nowrap">
                Active Sales Bidded
              </TableCell>
              <TableCell className="whitespace-nowrap">
                Active Revenue
              </TableCell>
              <TableCell className="whitespace-nowrap">Sold Revenue</TableCell>
              <TableCell className="whitespace-nowrap">Views</TableCell>
              <TableCell className="whitespace-nowrap">Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: theme.palette.dataTableBg }}>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell
                  className="whitespace-nowrap pl-6"
                  component="th"
                  scope="row">
                  <Link
                    className="link"
                    component={RouterLink}
                    to={`/collections/${item.id}`}>
                    {item.slug}
                  </Link>
                </TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  {showStats && item.saleType === 'auction'
                    ? item.sales?.stats?.saleCountActive.toLocaleString()
                    : '–'}
                </TableCell>
                <TableCell>
                  {showStats && item.saleType === 'auction'
                    ? item.sales?.stats?.saleCountActiveBidded?.toLocaleString() ??
                      '0'
                    : '–'}
                </TableCell>
                <TableCell>
                  {showStats && item.saleType === 'auction'
                    ? item.sales?.stats?.saleRevenueActive
                      ? formatCurrency(item.sales.stats.saleRevenueActive)
                      : '0'
                    : '–'}
                </TableCell>
                <TableCell>
                  {showStats && item.saleType === 'auction'
                    ? item.sales?.stats?.saleRevenueSold
                      ? formatCurrency(item.sales.stats.saleRevenueSold)
                      : '0'
                    : '–'}
                </TableCell>
                <TableCell>
                  {showStats && item.saleType === 'auction'
                    ? item.sales?.stats?.saleViewCount.toLocaleString()
                    : '–'}
                </TableCell>
                <TableCell className="whitespace-nowrap">
                  {new Date(item.createdDate).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer
        anchor="right"
        open={!!params.id}
        onClose={() => history.push('/collections')}>
        <div className="flex p-3">
          <IconButton onClick={() => history.push('/collections')}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className="p-8 max-w-screen-sm w-screen">
          <form
            className="sticky top-24"
            onSubmit={async event => {
              event.preventDefault();
              if (formData.domains.indexOf(',') !== -1) {
                window.alert('Invalid domains input');
                return;
              }
              await onUpdateCollection(cursor?.id, {
                domains: formData.domains
                  .split('\n')
                  .map(s => s.trim())
                  .filter(s => !!s),
                heading: formData.heading,
                saleType: formData.saleType,
                slug: formData.slug,
                sortType: formData.sortType,
                subheading: formData.subheading,
                theme: formData.theme,
                type: formData.type,
                visibility: formData.visibility,
              });
            }}>
            <Typography gutterBottom variant="h4">
              {cursor?.heading ?? 'New collection'}
            </Typography>
            <TextField
              fullWidth
              id="heading"
              label="Heading"
              margin="normal"
              onChange={event =>
                setFormData({ ...formData, heading: event.target.value })
              }
              required
              value={formData.heading}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Subheading"
              margin="normal"
              multiline
              onChange={event =>
                setFormData({ ...formData, subheading: event.target.value })
              }
              value={formData.subheading}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={cursor?.type === 'auto'}
              id="uri"
              inputProps={{
                onInvalid: event =>
                  event.target.setCustomValidity('Please use kebab case only.'),
                pattern: '([-_]*[a-zA-Z0-9]+([-_]*[a-zA-Z0-9]+)*)',
              }}
              label="URI"
              margin="normal"
              onChange={event =>
                setFormData({ ...formData, slug: event.target.value })
              }
              value={formData.slug}
              variant="outlined"
            />
            {formData.type === 'manual' && (
              <TextField
                error={formData.domains.indexOf(',') !== -1}
                fullWidth
                inputProps={{
                  className: 'font-mono',
                  spellCheck: 'false',
                }}
                label="Domains (one per line)"
                margin="normal"
                maxRows={20}
                multiline
                onChange={event =>
                  setFormData({ ...formData, domains: event.target.value })
                }
                value={formData.domains}
                variant="outlined"
              />
            )}
            <div className="my-4">
              <FormControl
                className="tw-block"
                component="fieldset"
                margin="normal">
                <FormLabel component="legend">Type</FormLabel>
                <FormHelperText>
                  Auto collections are populated with sales automatically based
                  on internal logic.
                </FormHelperText>
                <RadioGroup
                  aria-label="collection type"
                  name="type"
                  onChange={event =>
                    setFormData({ ...formData, type: event.target.value })
                  }
                  row
                  value={formData.type}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Auto"
                    value="auto"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Manual"
                    value="manual"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {formData.type === 'manual' && (
              <div className="mb-4">
                <FormControl
                  className="tw-block"
                  component="fieldset"
                  margin="normal">
                  <FormLabel component="legend">Sort</FormLabel>
                  <FormHelperText>
                    Manual sort will follow same order as values above.
                  </FormHelperText>
                  <RadioGroup
                    aria-label="sort"
                    name="sort"
                    onChange={event =>
                      setFormData({
                        ...formData,
                        sortType: event.target.value,
                      })
                    }
                    row
                    value={formData.sortType}>
                    <FormControlLabel
                      control={<Radio />}
                      label="Auto"
                      value="auto"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Manual"
                      value="manual"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            <div className="mb-4">
              <FormControl
                className="tw-block"
                component="fieldset"
                margin="normal">
                <FormLabel component="legend">Inventory</FormLabel>
                <FormHelperText>
                  Collections can either be buy now or auctions, but not both.
                </FormHelperText>
                <RadioGroup
                  aria-label="sale type"
                  name="saleType"
                  onChange={event =>
                    setFormData({ ...formData, saleType: event.target.value })
                  }
                  row
                  value={formData.saleType}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Auctions"
                    value="auction"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Buy Now"
                    value="buy"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="mb-4">
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Color Theme</FormLabel>
                <FormHelperText>e.g. orange or blue buttons</FormHelperText>
                <RadioGroup
                  aria-label="theme"
                  name="theme"
                  onChange={event =>
                    setFormData({ ...formData, theme: event.target.value })
                  }
                  row
                  value={formData.theme}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Primary"
                    value="primary"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Secondary"
                    value="secondary"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Visibility</FormLabel>
                <FormHelperText>
                  Controls where collection is seen. 'Public' means visible on
                  homepage. 'Unlisted' means not shown on homepage but
                  accessible by direct link. 'Private' means the collection is
                  not shown anywhere and accessing by direct link will show a
                  Not Found error.
                </FormHelperText>
                <RadioGroup
                  aria-label="visibility"
                  name="visibility"
                  onChange={event =>
                    setFormData({ ...formData, visibility: event.target.value })
                  }
                  row
                  value={formData.visibility}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Public"
                    value="public"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Unlisted"
                    value="unlisted"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Private"
                    value="private"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="mt-8 flex items-center">
              <Button color="primary" type="submit" variant="contained">
                Save
              </Button>
              <div className="flex-auto text-right">
                {cursor && (
                  <Button
                    classes={{ root: 'text-red-500' }}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      if (
                        cursor.heading ===
                        window.prompt(
                          'Please confirm you wish to delete this collection by typing the name:',
                        )
                      ) {
                        onDeleteCollection(cursor.id);
                      }
                    }}
                    variant="outlined">
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
}
