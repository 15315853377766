import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getAuthLoginRedirect } from '../config/auth';

const paginatedFieldPolicy = {
  keyArgs: false,
  merge(existing = {}, incoming) {
    return {
      ...existing,
      ...incoming,
      items: [...(existing.items ?? []), ...incoming.items],
    };
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        bidding: paginatedFieldPolicy,
        invoices: paginatedFieldPolicy,
      },
    },
  },
});

const httpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(error => console.error('[GraphQL error]:', error));
    if (
      graphQLErrors.some(error => error.extensions?.code === 'UNAUTHENTICATED')
    ) {
      window.location.href = getAuthLoginRedirect();
    }
  }
  if (networkError) console.error('[Network error]:', networkError);
});

const getApolloClient = () =>
  new ApolloClient({
    cache,
    link: errorLink.concat(httpLink),
  });

export default getApolloClient;
