import {
  IconButton,
  Tooltip,
  useTheme,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import useDarkMode from '../lib/useDarkMode';

export default function DarkModeToggle() {
  const [, setDarkMode, enabledState] = useDarkMode();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  let label, onClick, icon;

  switch (enabledState) {
    case true:
      label = 'On';
      onClick = () => setDarkMode(false);
      icon = <Brightness4Icon />;
      break;
    case false:
      label = 'Off';
      onClick = () => setDarkMode(null);
      icon = <Brightness7Icon />;
      break;
    default:
      label = 'Auto';
      onClick = () => setDarkMode(true);
      icon = <BrightnessAutoIcon />;
      break;
  }

  if (isLarge) {
    return (
      <Tooltip className="hidden lg:block" title={`Dark mode: ${label}`}>
        <IconButton
          color="primary"
          aria-label={`Dark mode: ${label}`}
          onClick={onClick}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button
      aria-label={`Dark mode: ${label}`}
      color="primary"
      startIcon={icon}
      onClick={onClick}>
      Dark mode: {label}
    </Button>
  );
}
