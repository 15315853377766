import { cloneElement } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import DarkModeToggle from './DarkModeToggle';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;

  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(props) {
  const styles = useStyles();
  const location = useLocation();
  return (
    <ElevationScroll>
      <AppBar className={styles.appBar} color="inherit" position="sticky">
        <Toolbar className="p-2 sm:px-4 lg:px-6">
          <div className="ml-3 sm:ml-0">
            <IconButton
              aria-label="open drawer"
              className="mr-2 z-10 lg:hidden"
              color="inherit"
              edge="start"
              onClick={props.onToggleNav}>
              <MenuIcon />
            </IconButton>
          </div>
          <Typography variant="h6" component="div">
            {
              {
                '': 'Dashboard',
                comments: 'Comments',
                collections: 'Collections',
                issues: 'Issues',
                sales: 'Auctions',
                users: 'Users',
              }[location.pathname.split('/')[1]]
            }
          </Typography>
          <div className="flex-auto"></div>
          <div className="hidden lg:block">
            <DarkModeToggle />
          </div>
          <Button color="primary" component={RouterLink} to={`/logout`}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
