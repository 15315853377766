import formatCurrency from '@nc-aftermarket/shared/utils/formatCurrency';

export { formatCurrency };

export function formatDate(timestamp) {
  return new Date(timestamp).toLocaleString();
}

export function formatTime(timestamp) {
  return new Date(timestamp).toLocaleTimeString();
}

export function formatNumber(input) {
  return input.toLocaleString();
}
