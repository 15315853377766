import { createContext, useContext } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useLocalStorage from './useLocalStorage';

const darkModeContext = createContext();

export function DarkModeProvider({ children }) {
  const darkMode = useDarkModeProvider();
  return (
    <darkModeContext.Provider value={darkMode}>
      {children}
    </darkModeContext.Provider>
  );
}

export default function useDarkMode() {
  return useContext(darkModeContext);
}

// Hook
function useDarkModeProvider() {
  // Use our useLocalStorage hook to persist state through a page refresh.
  // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
  const [enabledState, setEnabledState] = useLocalStorage('dark-mode-enabled');

  // See if user has set a browser or OS preference for dark mode.
  // The usePrefersDarkMode hook composes a useMedia hook (see code below).
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
  // This allows user to override OS level setting on our website.
  const isDark =
    typeof enabledState === 'undefined' || enabledState === null
      ? prefersDarkMode
      : enabledState;

  // // Fire off effect that add/removes dark mode class
  // useEffect(
  //   () => {
  //     const className = 'dark-mode';
  //     const element = window.document.body;
  //     if (enabled) {
  //       element.classList.add(className);
  //     } else {
  //       element.classList.remove(className);
  //     }
  //   },
  //   [enabled], // Only re-call effect when value changes
  // );

  // Return enabled state and setter
  return [isDark, setEnabledState, enabledState];
}
