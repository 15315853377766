import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { Link, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { getAuthLoginRedirect, getAuthLogoutRedirect } from '../config/auth';
import Panel from '../components/Panel';
import useAuth from '../lib/useAuth';

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

export default function Logout() {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [success, setSuccess] = useState(params.get('success'));
  const [logout] = useMutation(LOGOUT_MUTATION);
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    if (!params.get('success')) {
      logout()
        .then(() => {
          setIsAuthenticated(false);
          window.location.href = getAuthLogoutRedirect();
        })
        .catch(() => {
          setSuccess(false);
          window.alert('Logout unsuccessful');
        });
    }
  }, [logout, params, setIsAuthenticated]);

  if (success === null) {
    return null;
  }

  return (
    <Panel>
      <Typography display="block" gutterBottom variant="h6">
        Aftermarket Console
      </Typography>
      {success ? (
        <>
          You’ve been logged out.{' '}
          <Link href={getAuthLoginRedirect()}>Log in again.</Link>
        </>
      ) : (
        <Alert className="mt-4" severity="error">
          Sign-out failed. Please try again later.
        </Alert>
      )}
    </Panel>
  );
}
