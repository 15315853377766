import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function URLParamsToObject(entries) {
  let result = {};
  for (let entry of entries) {
    // each 'entry' is a [key, value] tupple
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}

export default function useTableUtils(options) {
  const { onParseFilter = params => params } = options || {};
  const history = useHistory();
  const location = useLocation();

  const filter = useMemo(() => {
    return onParseFilter(
      URLParamsToObject(new URLSearchParams(location.search)),
    );
  }, [location.search, onParseFilter]);

  const setFilter = useCallback(
    filters => {
      const params = new URLSearchParams(location.search);
      Object.keys(filters).forEach(key => {
        const value = filters[key];
        if (!value) {
          params.delete(key);
        } else {
          params.set(key, value);
        }
      });
      history.replace({
        ...location,
        search: `?${params.toString()}`,
      });
    },
    [history, location],
  );

  const setState = useCallback(
    state => {
      history.replace({
        ...location,
        state: {
          ...location.state,
          ...state,
        },
      });
    },
    [history, location],
  );

  return {
    filter,
    setFilter,
    setState,
    state: location.state || {},
  };
}
