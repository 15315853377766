import { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useMutation, gql } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress, Typography } from '@material-ui/core';
import Panel from '../components/Panel';
import { getAuthLoginRedirect } from '../config/auth';
import useAuth from '../lib/useAuth';

const LOGIN_MUTATION = gql`
  mutation LoginWithAuthCode($code: String!, $redirectURI: String!) {
    loginWithAuth0AuthCode(input: { code: $code, redirectURI: $redirectURI }) {
      success
    }
  }
`;

export default function Login() {
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [login] = useMutation(LOGIN_MUTATION);
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const code = params.get('code');
    if (code) {
      login({
        variables: {
          code,
          redirectURI: `${window.location.origin}/login`,
        },
      })
        .then(response => {
          if (response.data.loginWithAuth0AuthCode.success) {
            setSuccess(true);
            setIsAuthenticated(true);
            history.push('/');
          } else {
            setSuccess(false);
          }
        })
        .catch(error => {
          console.error(error);
          setSuccess(false);
        });
    } else {
      window.location.href = getAuthLoginRedirect();
    }
  }, [history, params, login, setIsAuthenticated]);

  return (
    <Panel>
      <Typography display="block" gutterBottom variant="h6">
        Aftermarket Console
      </Typography>
      {success === false ? (
        <Alert className="mt-4" severity="error">
          Sign-in failed. Please try again later.
        </Alert>
      ) : (
        <>
          <CircularProgress className="my-4" />
          <Typography>Logging in…</Typography>
        </>
      )}
    </Panel>
  );
}
