import cx from 'classnames';
import { useRef, useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import HomeIcon from '@material-ui/icons/Home';
import GavelIcon from '@material-ui/icons/Gavel';
import CommentsIcon from '@material-ui/icons/QuestionAnswer';
import IssuesIcon from '@material-ui/icons/Flag';
import {
  Badge,
  ClickAwayListener,
  Divider,
  useMediaQuery,
  // Collapse,
} from '@material-ui/core';
import DarkModeToggle from './DarkModeToggle';
import { gql, useQuery } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer,
  },
  drawer: {
    // Drawer animation:
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  drawerShadow: {
    boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up('lg')]: {
      boxShadow: 'none',
    },
  },
  drawerHide: {
    boxShadow: 'none',
    marginLeft: '-1px',
    [theme.breakpoints.up('lg')]: {
      width: null,
    },
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
  },
  nestedList: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
  },
}));

export default function Nav({ open, onClose }) {
  const location = useLocation();
  const history = useHistory();
  const styles = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const prevIsLarge = useRef();
  const shouldCloseOnNav = useRef();

  shouldCloseOnNav.current = !isLarge && open;

  useEffect(() => {
    history.listen((_location, action) => {
      if (action === 'PUSH' && shouldCloseOnNav.current) {
        onClose();
      }
    });
  }, [history, onClose, shouldCloseOnNav]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
    } else {
      document.body.classList.remove('overflow-hidden', 'lg:overflow-auto');
    }
  }, [open]);

  // Close drawer when resizing down
  useEffect(() => {
    if (!isLarge && prevIsLarge.current) {
      onClose();
    }
    prevIsLarge.current = isLarge;
  }, [isLarge, onClose]);

  const clickAway = event => {
    if (!isLarge && open) {
      event.preventDefault();
      onClose();
    }
  };

  const pendingComments = useQuery(gql`
    query NavPendingCommentsCount {
      comments(filter: { status: pending }) {
        total
      }
    }
  `);

  const openIssues = useQuery(gql`
    query OpenIssuesCount {
      issues(filter: { status: open }) {
        total
      }
    }
  `);

  return (
    <ClickAwayListener
      onClickAway={clickAway}
      mouseEvent="onMouseUp"
      touchEvent="onTouchEnd">
      <Drawer
        anchor="left"
        className={cx(
          styles.root,
          styles.drawer,
          styles.drawerShadow,
          {
            [styles.drawerHide]: !open,
            'w-0': !open,
            'lg:w-56': open,
          },
          'relative flex-shrink-0',
        )}
        classes={{
          paper: cx(styles.drawer, 'flex flex-col', {
            [styles.drawerHide]: !open,
            'w-0': !open,
            'w-56': open,
          }),
        }}
        variant="permanent">
        <div className="flex-auto">
          <div
            className={cx(
              styles.drawerHeader,
              'flex flex-col justify-center px-5 whitespace-nowrap',
            )}>
            <div className="text-base">⚙️ Aftermarket Console</div>
            {/* <div className="text-xs">v1.0.0</div> */}
          </div>
          <Divider />
          <List component="nav">
            <ListItem
              button
              component={RouterLink}
              to="/"
              selected={location.pathname === '/'}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/sales?status=current"
              selected={location.pathname.startsWith('/sales')}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Auctions" />
            </ListItem>
            {/* <Collapse
              in={location.pathname.startsWith('/sales')}
              timeout="auto"
              unmountOnExit>
              <List disablePadding>
                <ListItem
                  button
                  component={RouterLink}
                  to="/sales?report=last30"
                  selected={location.search === '?report=last30'}>
                  <ListItemIcon>
                    <div />
                  </ListItemIcon>
                  <ListItemText primary="Last 30 days" />
                </ListItem>
                <ListItem
                  button
                  component={RouterLink}
                  to="/sales?report=endingToday"
                  selected={location.search === '?report=endingToday'}>
                  <ListItemIcon>
                    <div />
                  </ListItemIcon>
                  <ListItemText primary="Ending today" />
                </ListItem>
              </List>
            </Collapse> */}
            <ListItem
              button
              component={RouterLink}
              to="/collections"
              selected={location.pathname.startsWith('/collections')}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Collections" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/comments"
              selected={location.pathname === '/comments'}>
              <ListItemIcon>
                <Badge
                  badgeContent={pendingComments.data?.comments.total ?? 0}
                  color="error"
                  overlap="rectangular">
                  <CommentsIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Comments" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/users"
              selected={location.pathname === '/users'}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/issues?status=open"
              selected={location.pathname === '/issues'}>
              <ListItemIcon>
                <Badge
                  badgeContent={openIssues.data?.issues.total ?? 0}
                  color="error"
                  overlap="rectangular">
                  <IssuesIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Issues" />
            </ListItem>
          </List>
        </div>
        <div className="p-4 lg:hidden">
          <DarkModeToggle />
        </div>
      </Drawer>
    </ClickAwayListener>
  );
}
