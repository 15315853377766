import _upperFirst from 'lodash/upperFirst';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import Activity from '../components/Activity';
import DataTable from '../components/DataTable';
import { formatDate } from '../lib/formatters';
import useTableUtils from '../lib/useTableUtils';
import useWaitCursor from '../lib/useWaitCursor';

const PAGE_QUERY = gql`
  query IssuesPage($filter: IssueFilter, $page: Int, $pageSize: Int) {
    issues(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        description
        id
        status
        type
        updatedDate
        activity {
          ...Activity
        }
        sale {
          id
          product {
            name
          }
        }
        user {
          id
          username
        }
      }
      page
      pageCount
      pageSize
      total
    }
  }
  ${Activity.fragments.activity}
`;

const columns = [
  {
    isSortable: false,
    key: 'id',
    label: 'ID',
  },
  {
    isSortable: false,
    key: 'type',
    label: 'Type',
  },
  {
    isSortable: false,
    key: 'description',
    label: 'Description',
  },
  {
    isSortable: false,
    key: 'status',
    label: 'Status',
    Cell: row => _upperFirst(row.status),
  },
  {
    accessor: row => row.sale.product.name,
    isSortable: false,
    key: 'sale',
    label: 'Sale',
    Cell: row =>
      row.sale ? (
        <Link component={RouterLink} to={`/sales/${row.sale.id}`}>
          {row.sale.product.name}
        </Link>
      ) : null,
  },
  {
    accessor: row => row.user.username,
    isSortable: false,
    key: 'username',
    label: 'User',
    Cell: row => (
      <Link component={RouterLink} to={`/users/${row.user.id}`}>
        {row.user.username}
      </Link>
    ),
  },
  {
    isSortable: false,
    key: 'updatedDate',
    label: 'Last Updated',
    Cell: row => formatDate(row.updatedDate),
  },
  {
    isSortable: false,
    key: 'actions',
    label: 'Actions',
    Cell: (row, location) => {
      return (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit bid"
            component={RouterLink}
            color="inherit"
            edge="start"
            to={{ ...location, hash: String(row.id) }}>
            <VisibilityIcon className="w-5 h-5" />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

const filterDefs = [
  {
    facets: [
      {
        label: 'Any',
        value: '',
      },
      {
        label: 'Open',
        value: 'open',
      },
      {
        label: 'Closed',
        value: 'closed',
      },
    ],
    formatter: _upperFirst,
    label: 'Status',
    key: 'status',
    type: 'facet',
  },
];

export default function Issues() {
  const location = useLocation();
  const history = useHistory();
  const { filter, setFilter, setState, state } = useTableUtils();
  const [cursor, setCursor] = useState(null);
  const [statusInput, setStatusInput] = useState('');
  const [note, setNote] = useState('');
  const hashValue = useMemo(() => {
    try {
      return window.parseInt(location.hash.replace('#', ''));
    } catch (error) {
      return null;
    }
  }, [location.hash]);

  const { page = 1, pageSize = 100 } = state;

  const { data, error, loading } = useQuery(PAGE_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      page,
      pageSize,
      filter: {
        status: filter.status,
      },
    },
  });

  useEffect(() => {
    if (data && hashValue) {
      const newCursor = data.issues.items.find(item => item.id === hashValue);
      if (newCursor) {
        setCursor(newCursor);
        setStatusInput(newCursor.status);
      } else {
        history.replace({ ...location, hash: null });
      }
    }
  }, [data, history, hashValue, location]);

  const [updateIssue, updateIssueQuery] = useMutation(
    gql`
      mutation UpdateIssue($id: Int!, $status: ISSUE_STATUS) {
        updateIssue(input: { id: $id, status: $status }) {
          issue {
            id
            status
          }
          success
        }
      }
    `,
    {
      refetchQueries: ['OpenIssuesCount', 'IssuesPage'],
    },
  );

  const [attachAdminNote, attachAdminNoteQuery] = useMutation(gql`
    mutation AttachAdminNote($note: String!, $issue: Int!) {
      attachAdminNote(input: { note: $note, issue: $issue }) {
        issue {
          id
          activity {
            ...Activity
          }
        }
        success
      }
    }
    ${Activity.fragments.activity}
  `);

  useWaitCursor(
    loading || updateIssueQuery.loading || attachAdminNoteQuery.loading,
  );

  const onSubmit = async event => {
    event.preventDefault();
    try {
      if (statusInput !== cursor.status) {
        await updateIssue({
          variables: {
            id: cursor.id,
            status: statusInput,
          },
        });
      }
      await attachAdminNote({
        variables: { issue: cursor.id, note },
      });
      setNote('');
    } catch (error) {
      window.alert('Failed to edit comment');
      console.error(error);
    }
  };

  if (error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>Issues</title>
      </Helmet>
      <Drawer
        anchor="right"
        open={!!hashValue}
        onClose={() => history.push({ ...location, hash: null })}>
        <div className="flex p-3">
          <IconButton onClick={() => history.push({ ...location, hash: null })}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <form className="p-8 max-w-screen-sm w-screen" onSubmit={onSubmit}>
          <Typography gutterBottom variant="h5">
            Issue #{cursor?.id}
          </Typography>
          <div className="my-6">
            <FormControl className="w-full" variant="outlined">
              <InputLabel htmlFor="status">Select a status</InputLabel>
              <Select
                inputProps={{
                  id: 'status',
                }}
                native
                value={statusInput}
                label="Select a status"
                onChange={event => setStatusInput(event.target.value)}>
                <option value="" disabled>
                  Select a status
                </option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </Select>
            </FormControl>
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
          </div>
          <div className="flex justify-end">
            <Button color="primary" type="submit" variant="contained">
              Save
            </Button>
          </div>
          <Activity activity={cursor?.activity} />
        </form>
      </Drawer>
      <DataTable
        data={data?.issues}
        columnDefs={columns}
        filter={filter}
        filterDefs={filterDefs}
        loading={loading}
        onSetPage={page => setState({ page })}
        onSetPageSize={pageSize => setState({ page: 1, pageSize })}
        onSetFilter={setFilter}
        onSort={(order, orderBy) => setState({ order, orderBy })}
        page={page}
        pageSize={pageSize}
        showSearch={false}
      />
    </>
  );
}
