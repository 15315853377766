import moment from 'moment';
import { useState, useEffect } from 'react';

function formatDuration(ms) {
  const duration = moment.duration(Math.abs(ms));
  if (ms < 0) {
    return 'Ended';
  }
  if (duration.asDays() > 1) {
    return Math.floor(duration.asDays()) + ' days';
  }
  const strings = {
    h: Math.floor(duration.asHours()).toString().padStart(2, '0'),
    m: duration.minutes().toString().padStart(2, '0'),
    s: duration.seconds().toString().padStart(2, '0'),
  };
  return `${strings.h}:${strings.m}:${strings.s}`;
}

export default function useCountdown(to) {
  const [countdownMs, setCountdownMs] = useState();
  const value = countdownMs ? formatDuration(countdownMs) : null;

  useEffect(() => {
    if (to) {
      const update = () => setCountdownMs(moment(to).diff(moment()));
      update();
      const interval = setInterval(() => {
        update();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [to]);

  return value;
}
