import _upperFirst from 'lodash/upperFirst';
import { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  TableContainer,
  TextField,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BlockIcon from '@material-ui/icons/Block';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from '@material-ui/icons/Create';
import ForumIcon from '@material-ui/icons/Forum';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Alert, Skeleton } from '@material-ui/lab';
import Activity from '../components/Activity';
import { formatCurrency, formatDate } from '../lib/formatters';
import useWaitCursor from '../lib/useWaitCursor';

const pageSize = 500;
const truncateLengthInitialPage = 10;

export default function User() {
  const [drawerView, setDrawerView] = useState(null);
  const [note, setNote] = useState('');
  const params = useParams();
  const [bidsTruncated, setBidsTruncated] = useState(true);
  const [invoicesTruncated, setInvoicesTruncated] = useState(true);

  const userQuery = useQuery(
    gql`
      query User($id: String!) {
        user(id: $id, idType: id) {
          id
          bidCount
          createdDate
          isBetaTester
          invoiceCount
          invoiceCountExpired: invoiceCount(type: expired)
          lastSeenDate
          level
          name
          picture
          role
          status
          totalRevenue
          username
          winCount
          activity {
            ...Activity
          }
          subscription {
            expiredDate
            plan
          }
          displayName {
            updatedDate
            value
          }
        }
      }
      ${Activity.fragments.activity}
    `,
    {
      variables: {
        id: params.id,
      },
    },
  );

  const biddingQuery = useQuery(
    gql`
      query UserBidding($id: String!, $page: Int, $pageSize: Int) {
        user(id: $id, idType: id) {
          id
          bidding(page: $page, pageSize: $pageSize) {
            page
            pageCount
            pageSize
            total
            items {
              id
              endDate
              status
              product {
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        page: 1,
        pageSize,
        id: params.id,
      },
    },
  );

  const invoicesQuery = useQuery(
    gql`
      query UserInvoices(
        $id: String!
        $page: Int
        $pageSize: Int
        $sort: [InvoiceSort]
      ) {
        user(id: $id, idType: id) {
          id
          invoices(page: $page, pageSize: $pageSize, sort: $sort) {
            page
            pageCount
            pageSize
            total
            items {
              expiredDate
              isPaid
              isRunnerup
              sale {
                id
                price
                product {
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: params.id,
        page: 1,
        pageSize,
        sort: [
          {
            column: 'paymentExpiredDate',
            direction: 'desc',
          },
        ],
      },
    },
  );

  const [attachAdminNote] = useMutation(gql`
    mutation AttachAdminNote($note: String!, $user: String!) {
      attachAdminNote(input: { note: $note, user: $user }) {
        user {
          id
          activity {
            ...Activity
          }
        }
      }
    }
    ${Activity.fragments.activity}
  `);

  const [updateUser] = useMutation(gql`
    mutation UpdateUser(
      $isBetaTester: Boolean
      $isBanned: Boolean
      $isVerified: Boolean
      $user: String!
    ) {
      updateUser(
        input: {
          isBetaTester: $isBetaTester
          isBanned: $isBanned
          isVerified: $isVerified
          user: $user
        }
      ) {
        user {
          id
          isBetaTester
          status
          activity {
            ...Activity
          }
        }
      }
    }
    ${Activity.fragments.activity}
  `);

  useWaitCursor(userQuery.loading);

  const onSetBanned = async isBanned => {
    try {
      await updateUser({
        variables: { isBanned, user: params.id },
      });
      if (note) {
        await attachAdminNote({ variables: { note, user: params.id } });
        setNote('');
      }
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  };

  const onSetVerified = async () => {
    try {
      await updateUser({
        variables: { isVerified: true, user: params.id },
      });
      if (note) {
        await attachAdminNote({ variables: { note, user: params.id } });
        setNote('');
      }
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  };

  const onToggleBetaTester = async event => {
    try {
      await updateUser({
        variables: { isBetaTester: event.target.checked, user: params.id },
      });
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  };

  const onSubmitNote = async event => {
    event.preventDefault();
    try {
      await attachAdminNote({ variables: { note, user: params.id } });
      setNote('');
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert('Failed to save note.');
    }
  };

  const activity = useMemo(() => {
    if (!userQuery.data?.user) {
      return [];
    }
    const { user } = userQuery.data;
    const activity = user.activity.map(item => {
      const details = {};
      if (item.sale) {
        details['Sale'] = (
          <Link component={RouterLink} to={`/sales/${item.sale.id}`}>
            {item.sale.product.name}
          </Link>
        );
      }
      if (item.type === 'ADD_COMMENT' || item.type === 'FLAG_COMMENT') {
        details['Comment'] = item.comment.body;
      }
      if (item.type === 'SET_USER_STATUS') {
        details['New status'] = item.description;
      }
      if (item.type === 'SET_WEBHOOK_URL') {
        details['URL'] = item.description;
      }
      if (item.type === 'ADD_ISSUE') {
        details['Issue'] = (
          <Link component={RouterLink} to={`/issues#${item.issue.id}`}>
            #{item.issue.id}: {item.issue.type}{' '}
            {item.issue.description && `: ${item.issue.description}`}
          </Link>
        );
      }
      if (item.type === 'SET_DISPLAY_NAME') {
        details[''] = item.description;
      }
      return {
        ...item,
        details,
      };
    });
    activity.push({
      createdDate: user.createdDate,
      title: 'User created',
    });
    return activity;
  }, [userQuery.data]);

  const error = userQuery.error || biddingQuery.error || invoicesQuery.error;

  if (error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {error.message}
      </Alert>
    );
  }

  if (userQuery.data && !userQuery.data.user) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        User not found
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>{userQuery.data?.user.username ?? 'Loading...'}</title>
      </Helmet>
      <div className="py-4">
        <div className="px-4 lg:px-8">
          <div className="flex mb-8">
            {userQuery.loading ? (
              <Skeleton className="w-16 h-16" variant="circle" />
            ) : (
              <Avatar className="w-16 h-16" src={userQuery.data.user.picture} />
            )}
            <div className="flex-auto ml-4">
              <div className="lg:flex items-end">
                <div className="flex-auto">
                  <Typography className="mb-1 break-words" variant="h4">
                    {userQuery.loading ? (
                      <Skeleton variant="text" width={300} />
                    ) : (
                      <>
                        {userQuery.data.user.username ||
                          userQuery.data.user.name ||
                          userQuery.data.user.displayName.value}
                        {userQuery.data.user.role === 'client' && (
                          <Chip
                            className="ml-4"
                            label={_upperFirst(userQuery.data.user.status)}
                          />
                        )}
                      </>
                    )}
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2">
                    {userQuery.loading ? (
                      <>
                        <Skeleton variant="text" width={300} />
                        <Skeleton variant="text" width={300} />
                      </>
                    ) : userQuery.data.user.lastSeenDate ? (
                      `Last seen ${formatDate(
                        userQuery.data.user.lastSeenDate,
                      )}`
                    ) : (
                      ''
                    )}
                  </Typography>
                </div>
                {userQuery.data && userQuery.data.user.role === 'client' && (
                  <div className="mt-4">
                    <FormControlLabel
                      className="ml-0"
                      control={
                        <Switch
                          checked={userQuery.data.user.isBetaTester}
                          className="mr-1"
                          color="primary"
                          name="checkedB"
                          onChange={onToggleBetaTester}
                        />
                      }
                      label="Beta Tester"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {userQuery.data && userQuery.data.user.role === 'client' && (
            <>
              <Divider />
              <div className="flex items-center lg:justify-start my-3">
                <Button
                  className="p-3"
                  classes={{
                    label: 'flex-col normal-case',
                    startIcon: 'mx-0 mb-2',
                  }}
                  onClick={() =>
                    setDrawerView(
                      userQuery.data.user.status === 'banned'
                        ? 'reactivate'
                        : 'ban',
                    )
                  }
                  startIcon={
                    userQuery.data.user.status === 'banned' ? (
                      <LockOpenIcon />
                    ) : (
                      <BlockIcon />
                    )
                  }
                  variant="text">
                  {userQuery.data.user.status === 'banned'
                    ? 'Re-activate user'
                    : 'Ban user'}
                </Button>
                {userQuery.data.user.status === 'unverified' && (
                  <Button
                    className="p-3"
                    disabled={userQuery.data.user.status === 'banned'}
                    classes={{
                      label: 'flex-col normal-case',
                      startIcon: 'mx-0 mb-2',
                    }}
                    onClick={() => setDrawerView('verify')}
                    startIcon={<VerifiedUserIcon />}
                    variant="text">
                    Mark verified
                  </Button>
                )}
                <Button
                  className="p-3"
                  classes={{
                    label: 'flex-col normal-case',
                    startIcon: 'mx-0 mb-2',
                  }}
                  onClick={() => setDrawerView('addNote')}
                  startIcon={<CreateIcon />}
                  variant="text">
                  Add a note
                </Button>
              </div>
              <div className="mt-6 mb-2">
                <Typography variant="overline">Details</Typography>
                <Divider />
              </div>
              <List disablePadding>
                <ListItem dense disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <TagFacesIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`Display name: ${userQuery.data?.user.displayName?.value}`}
                    secondary={
                      userQuery.data?.user.role === 'client'
                        ? `NC username: ${userQuery.data?.user.username}`
                        : ''
                    }
                  />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <ForumIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${userQuery.data?.user.bidCount.toLocaleString()} bids`}
                    secondary={`Across ${biddingQuery.data?.user.bidding.total.toLocaleString()} sales`}
                  />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <ReceiptIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${userQuery.data?.user.invoiceCount.toLocaleString()} invoices`}
                    secondary={`${userQuery.data?.user.invoiceCountExpired.toLocaleString()} expired`}
                  />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <AttachMoneyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatCurrency(userQuery.data?.user.totalRevenue)}
                    secondary={`Total revenue (Level: ${userQuery.data?.user.level.toLocaleString()}+) (Domains won: ${userQuery.data?.user.winCount.toLocaleString()})`}
                  />
                </ListItem>
                {userQuery.data?.user.subscription && (
                  <ListItem dense disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <PaymentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Subscribed to ${userQuery.data.user.subscription.plan}`}
                      secondary={`Renews on ${formatDate(
                        userQuery.data.user.subscription.expiredDate,
                      )}`}
                    />
                  </ListItem>
                )}
                <ListItem dense disableGutters>
                  <ListItemAvatar>
                    <Avatar>
                      <ScheduleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`User created on ${formatDate(
                      userQuery.data.user.createdDate,
                    )}`}
                    secondary="This is first time this user logged in to the Aftermarket"
                  />
                </ListItem>
              </List>
            </>
          )}
        </div>
        {biddingQuery.data && userQuery.data?.user.role === 'client' && (
          <div className="px-4 lg:px-8">
            <div className="mt-4">
              <Typography variant="overline">Bidding</Typography>
            </div>
            <TableContainer component={Paper} elevation={0} variant="outlined">
              <Table className="whitespace-nowrap" aria-label="Bidding">
                <TableHead>
                  <TableRow>
                    <TableCell>Sale</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {biddingQuery.data.user.bidding?.items
                    .slice(
                      0,
                      bidsTruncated ? truncateLengthInitialPage : undefined,
                    )
                    .map(sale => (
                      <TableRow key={sale.id}>
                        <TableCell>
                          <Link component={RouterLink} to={`/sales/${sale.id}`}>
                            {sale.product.name}
                          </Link>
                        </TableCell>
                        <TableCell>{_upperFirst(sale.status)}</TableCell>
                        <TableCell>
                          {new Date(sale.endDate).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  {biddingQuery.data.user.bidding.total === 0 && (
                    <TableRow>
                      <TableCell colSpan={8}>User has no bids.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {((bidsTruncated &&
              truncateLengthInitialPage <
                biddingQuery.data.user.bidding.total) ||
              biddingQuery.data.user.bidding.items.length <
                biddingQuery.data.user.bidding.total) && (
              <Button
                className="my-2"
                color="primary"
                onClick={() => {
                  if (bidsTruncated) {
                    setBidsTruncated(false);
                  } else {
                    biddingQuery.refetch({
                      pageSize,
                      page: biddingQuery.data.user.bidding.page + 1,
                      id: params.id,
                    });
                  }
                }}
                size="small">
                Show more
              </Button>
            )}
          </div>
        )}
        {invoicesQuery.data && userQuery.data?.user.role === 'client' && (
          <div className="px-4 lg:px-8">
            <div className="mt-4">
              <Typography variant="overline">Invoices</Typography>
            </div>
            <TableContainer component={Paper} elevation={0} variant="outlined">
              <Table className="whitespace-nowrap" aria-label="Invoices">
                <TableHead>
                  <TableRow>
                    <TableCell>Sale</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Paid</TableCell>
                    <TableCell>Runnerup</TableCell>
                    <TableCell>Expire Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoicesQuery.data.user.invoices?.items
                    .slice(
                      0,
                      invoicesTruncated ? truncateLengthInitialPage : undefined,
                    )
                    .map((invoice, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={`/sales/${invoice.sale.id}`}>
                            {invoice.sale.product.name}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {formatCurrency(invoice.sale.price)}
                        </TableCell>
                        <TableCell>{invoice.isPaid ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          {invoice.isRunnerup ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell>
                          <span
                            className={
                              !invoice.isPaid &&
                              !invoice.isRunnerup &&
                              new Date(invoice.expiredDate) < new Date()
                                ? 'text-red-500'
                                : ''
                            }>
                            {new Date(invoice.expiredDate).toLocaleString()}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  {invoicesQuery.data.user.invoices.total === 0 && (
                    <TableRow>
                      <TableCell colSpan={8}>User has no invoices.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {((invoicesTruncated &&
              truncateLengthInitialPage <
                invoicesQuery.data.user.invoices.total) ||
              invoicesQuery.data.user.invoices.items.length <
                invoicesQuery.data.user.invoices.total) && (
              <Button
                className="my-2"
                color="primary"
                onClick={() => {
                  if (invoicesTruncated) {
                    setInvoicesTruncated(false);
                  } else {
                    invoicesQuery.refetch({
                      pageSize,
                      page: invoicesQuery.data.user.invoices.page + 1,
                      id: params.id,
                    });
                  }
                }}
                size="small">
                Show more
              </Button>
            )}
          </div>
        )}
        {activity.length > 0 && (
          <div className="p-4 lg:px-8">
            <div className="my-4">
              <Typography variant="overline">History</Typography>
              <Divider />
            </div>
            <Activity activity={activity} />
          </div>
        )}
      </div>
      <Drawer
        anchor="right"
        open={!!drawerView}
        onClose={() => setDrawerView(null)}>
        <div className="flex p-3">
          <IconButton onClick={() => setDrawerView('')}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {drawerView === 'addNote' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={onSubmitNote}>
            <Typography gutterBottom variant="h5">
              Add a note
            </Typography>
            <TextField
              className="my-4"
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Add Note
              </Button>
            </div>
          </form>
        )}
        {drawerView === 'ban' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={event => {
              event.preventDefault();
              if (
                window.confirm(
                  `Banning ${userQuery.data.user.username} will cancel all their winning bids on active (non-ended) sales. Are you sure you want to continue?`,
                )
              ) {
                onSetBanned(true);
              }
            }}>
            <Typography variant="h5">
              Ban user {userQuery.data.user.username}
            </Typography>
            <TextField
              className="my-4"
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Ban user
              </Button>
            </div>
          </form>
        )}
        {drawerView === 'reactivate' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={event => {
              event.preventDefault();
              onSetBanned(false);
            }}>
            <Typography variant="h5">
              Re-activate user {userQuery.data.user.username}
            </Typography>
            <TextField
              className="my-4"
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Re-activate user
              </Button>
            </div>
          </form>
        )}
        {drawerView === 'verify' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={event => {
              event.preventDefault();
              if (
                window.confirm(
                  `Manually marking user ${userQuery.data.user.username} as verified cannot be done. Are you sure you want to proceed?`,
                )
              ) {
                onSetVerified();
              }
            }}>
            <Typography variant="h5">
              Mark {userQuery.data.user.username} verified
            </Typography>
            <TextField
              className="my-4"
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Mark verified
              </Button>
            </div>
          </form>
        )}
      </Drawer>
    </>
  );
}
